// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$my-custom-typography-config: mat.define-typography-config(
  $font-family: 'Helvetica',
  $display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $display-1: mat.define-typography-level(34px, 40px, 300),
  $title:  mat.define-typography-level(32px, 32px, 500),
  $headline:  mat.define-typography-level(30px, 32px, 700),
  $subheading-2:  mat.define-typography-level(20px, 32px, 600),
  $subheading-1:  mat.define-typography-level(20px, 32px, 600),
);
@include mat.core($my-custom-typography-config);



// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$KVGen-primary: mat.define-palette(mat.$indigo-palette);
$KVGen-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$KVGen-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$KVGen-theme: mat.define-light-theme((
  color: (
    primary: $KVGen-primary,
    accent: $KVGen-accent,
    warn: $KVGen-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($KVGen-theme);


$blue-color: rgb(68, 102, 136);
$light-blue-color: rgba(211, 225, 230, 0.2);
.mat-tooltip {
  font-size: 15px !important;
  min-width: 500px !important;
  padding: 5px;
}

.accent-color {
  color: lightslategrey;
}

mat-datepicker-toggle {
  color: $blue-color !important;
}

.mat-raised-button {
  background-color: $blue-color !important;
  color: white !important;
  font-family: inherit;
  font-weight: 500;
  margin-right: 15px !important;
  margin-bottom: 15px !important;
  line-height: 2.2em !important;
}
.mat-raised-button:hover {
  background-color: $light-blue-color !important;
  color: $blue-color !important;
}

.save-button {
  background-color: rgb(177, 80, 80) !important;
  color: whitesmoke !important;
}

.save-button :hover {
  color: rgb(177, 80, 80) !important;
  background-color: whitesmoke !important;
}

.progress-open {
  border-radius: 50%;
  background-color: white;
  color: #000000;
  // position: absolute;
  margin: 1.5em;
  padding: 1.5em;
}

.progress-active {
  border-radius: 50%;
  background-color: #cbd857;
  color: white;
  // position: absolute;
  margin: 1.5em;
  padding: 1.5em;
}

.progress-finished {
  border-radius: 50%;
  background-color: #333333;
  color: white;
  // position: absolute;
  margin: 1.5em;
  padding: 1.5em;
}
.progress-wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 25%;
  margin-right: 25%;
}

ul {
  color: $blue-color;
}

textarea {
  align-self: left !important;
  margin: 1% !important;
}

.custom-top-head-line {
  border-top-color: $blue-color;
  border-top-width: 2px;
  border-top-style: solid;
  // margin: 2%;
  width: 98%;
}

div {
  font-family: "Helvetica";
  color: #000000;
}
.mat-radio-button {
  margin: 1%;
}

label {
  margin: 2px !important;
  padding: 1px !important;
  color: #000000;
  white-space: break-spaces;
}

mat-label {
  margin: 2px !important;
  padding: 1px !important;
}

.mat-radio-label-content,
.mat-input-element,
input {
  font-family: "Helvetica" !important;
  color: #333333 !important;
  margin-left: 2px !important;
}

span.mat-select-value-text {
  margin-left: 2px !important;
}

h2,
h3 {
  font-family: "Helvetica" !important;
  color: $blue-color !important;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

mat-checkbox {
  margin: 1%;
}

.mat-dialog-container {
  border: $blue-color;
  border-style: solid;
  border-width: 2px;
}

.custom-dlg-head {
  background-color: $blue-color;
  height: 20%;
  border-radius: 5px;
}

.custom-dlg-h {
  border-bottom: $blue-color;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0.7em;
  color: $blue-color;
}

.custom-icon {
  float: right;
  width: 5% !important;
  height: 5% !important;
}

.custom-icon-div {
  margin-top: 1%;
  height: 3.17em !important;
  background-color: $light-blue-color;
  border-right: 1px;
  border-right-style: solid;
  border-right-color: $blue-color;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: $blue-color;
}

.custom-icon-field {
  border-right-style: none !important;
  border-right-width: 0 !important;
  line-height: 1.2em !important;
  background-color: $light-blue-color;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: $blue-color;
  border-right-width: 0px;
  border-right-style: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $blue-color;
  color: $blue-color;
  width: calc(95% - 25px) !important;
  margin-top: 1%;
  margin-bottom: 1%;
}

mat-form-field {
  line-height: 1.2em !important;
  background-color: $light-blue-color;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: $blue-color;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: $blue-color;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $blue-color;
  color: $blue-color;
  width: 95% !important;
  margin-top: 1%;
  margin-bottom: 1%;
}

.no-padding {
  .mat-form-field-wrapper {
    padding-bottom: 1px !important;
  }
}

.custom-kosten {
  background-color: $light-blue-color !important;
  color: black !important;
}

.custom-box {
  background-color: $light-blue-color;
  border-width: 1px;
  border-style: solid;
  border-color: $blue-color;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 95%;

  .mat-raised-button {
    margin-left: 2px;
  }

  .mat-form-field {
    margin-left: 2px;
  }
}

.custom-top-line {
  border-top-color: $blue-color;
  border-top-width: 2px;
  border-top-style: dotted;
}

.mat-checkbox-label {
  white-space: break-spaces;
}

.mat-radio-label-content {
  white-space: break-spaces;
}

.fa-question {
  border-radius: 0 !important;
  color: white !important;
  background-color: $blue-color !important;
  width: 25px !important;
  height: 25px !important;
  top: 0 !important;
  right: 25px !important;
  padding: 2px !important;
  float: right;
}
.fa-question:hover {
  color: $blue-color !important;
  background-color: $light-blue-color !important;
}

.fa-times {
  padding: 2px !important;
  color: #666666;
  background-color: whitesmoke;
  width: 25px !important;
  height: 25px !important;
  float: right;
}

.fa-times:hover {
  color: whitesmoke;
  background-color: #666666;
}

.fa-check {
  margin-left: 1% !important;
  margin-right: 0.5% !important;
}

.fa-info:hover {
  color: $blue-color !important;
  background-color: $light-blue-color !important;
}

.fa-info {
  border-radius: 0 !important;
  color: white !important;
  background-color: $blue-color !important;
  width: 25px !important;
  height: 25px !important;
  top: 0 !important;
  right: 25px !important;
  padding: 2px !important;
  float: right;
}

.custom-weiter {
  background-color: white !important;
  color: $blue-color !important;
  float: right;
  font-size: 30px;
  // height: 7%;
  // width: 25%;
  font-family: inherit;
  font-weight: 500;
  margin-right: 5px !important;
  margin-bottom: 15px !important;
  line-height: 2.2em !important;
  border-width: 0px !important;
  box-shadow: 0px 0px 0px 0px white !important;
}

.custom-weiter-div {
  border-top-color: $blue-color !important;
  border-top-width: 2px !important;
  border-top-style: solid !important;
  width: 98%;
  // margin-left: 2%;
  // margin-right: 2%;
}

.custom-info {
  top: 0;
  right: 0;
}

.mat-input-element {
  line-height: 1.2em;
}

.customshit {
  line-height: 1.2em !important;
}

.custom-snackBar {
  color: red !important;
  background-color: rgba(185, 185, 185, 0.596) !important;
  width: 100% !important;

  .mat-button-wrapper {
    background-color: inherit;
    color: red !important;
  }
}
